import React from 'react';

class SliderInput extends React.Component{
    constructor(props) {
        super(props);
        this.updateVal = this.updateVal.bind(this);
      }
      
      updateVal(e){
          this.props.updateVal(e);
      }
    render(){
        return  (
            <div className="ui input fluid">
                   <input
                   type="range"
                   min={this.props.minV}
                   max={this.props.maxV}
                   step={this.props.stepV}
                   name="ValNumber"
                   onChange={(e)=>this.props.updateVal(e)}
                   value = {this.props.theDifficulity}
                   id="Slide"
                       />
            </div>   );
        
    }
}

export default(SliderInput);