import { UPDATE_CASTING_SKILL, UPDATE_CASTING_ROLLS, UPDATE_TALENT_CASTING, RESET_CASTING_HISTORY } from '../actionTypes'
const initialState = {
    SkillLevel: '1',
    talent: 0,
    rolls: []
}
const  castingTestReducer = (state = initialState, action) =>{
    switch(action.type){
        case UPDATE_CASTING_SKILL:
            const { skill } = action.payload;
            return {...state, 
                SkillLevel: skill
            }
        case UPDATE_CASTING_ROLLS:
                const { roll } = action.payload;
            
                //console.log('updating history', roll);
                return { ...state,
                    rolls: [ ...state.rolls, roll]
                }
        case UPDATE_TALENT_CASTING:
                const { talent } = action.payload;
                console.log(talent);
                return { ...state,
                    talent: talent
                }
        case RESET_CASTING_HISTORY:
            return{...state,
                rolls:[]    
            }
        default:
            return state;
    }
 
}
export default castingTestReducer