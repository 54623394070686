import React from 'react';
import { connect } from 'react-redux';
import { updateRangedSkill, updateRangedRoll, resetRangedHistory,updateWeaponQualR } from '../../redux/actions/index'

import Advantage from '../Advantage';
import SkillInput from '../SkillInput';
import History from '../History';
import { Button } from 'semantic-ui-react';
import {Modal, Icon} from 'semantic-ui-react';
import SliderInput from '../SliderInput';
import Toggle from '../Toggle';

import critical from '../../data/critical.json'
import fumble from '../../data/fumble.json'

//import './ranged.css'



//import './css/skill.css';

class RangedTest extends React.Component{
  constructor(props) {
    super(props);
   
    this.state = { 
      modalOpen: false,
      d100: 0,
      sl: 0,
      autoSuccess: false,
      autoFail: false,
      critical: false,
      fumble: false,
      difficulty: 0,
      hitLocation: '',
      criticalDescriptopn: '',
      criticalEffects: '',
      fumbleText: ''
    }
    this.rollDice = this.rollDice.bind(this);
    
  }

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })

  handleDifficulty = (e) => {this.setState({difficulty: e.target.value});}

  rollDice(){
    
    this.initResets();
    let roll = this.d100();
    //roll = 10
    
    this.setState({d100: roll});
    this.hitLocation(roll);
    this.checkAutoFailSuccess(roll);
    this.getSL(roll);
    this.checkCritFumble(roll);
    this.handleOpen();    
    
     
   
}

d100(){
  return Math.floor((Math.random()*100)+1);
}

initResets(){
  //reset state things for next roll
  this.setState({autoFail: false})
  this.setState({autoSuccess: false})
  this.setState({critical: false})
  this.setState({fumble: false})
  this.setState({hitLocation: ''})
  this.setState({criticalDescriptopn: ''})
  this.setState({criticalEffects: ''})
  this.setState({fumbleText: ''})
}

hitLocation(roll){
  let findLocation = 0;
  if (roll < 10 ){
      findLocation = roll*10;
  }else{findLocation = this.reverse(roll);}
  
  
  switch (true) {
      case (findLocation <= 9 ):
          this.setState({hitLocation: 'Head'})
          break;
          case (findLocation <= 24 ):
          this.setState({hitLocation:'Left Arm'})
          break;
          case (findLocation <= 44 ):
          this.setState({hitLocation:'Right Arm'})
          break;
          case (findLocation <= 79 ):
          this.setState({hitLocation:'Body'})
          break;
          case (findLocation <= 89 ):
          this.setState({hitLocation:'Left Leg'})
          break;
          case (findLocation <= 100 ):
          this.setState({hitLocation:'Right Leg'})
          break;
          default:
          
  }
  
}



reverse(n){
  n = n + "";
return Number(n.split("").reverse().join(""));
}

checkAutoFailSuccess(roll){
  switch(true){
    case (roll <= 5):
        this.setState({autoSuccess: true})
        break;
    case (roll >= 95):
        this.setState({autoFail: true})
        break;
    default:
      //neither
  }
}

checkCritFumble(roll){
  let accurate = 0;
  if (this.props.melee.weaponQualities.accurate){
    accurate = 10;
  }
  
  const modSkill = Number(this.props.melee.SkillLevel) + Number(this.state.difficulty)+Number(this.props.advantage)*10 + Number(accurate);
  switch(true){
    case(roll <= modSkill):       //posible critical
    
      if((roll % 11)===0){
        //set critical
        // load critical text
       
        this.setState({critical: true});
        this.genCriticalHit();
      }else if((roll % 10)===0 && this.props.melee.weaponQualities.impale){
        // set critical
        // load critical text
        
        this.setState({critical: true});
        this.genCriticalHit();
      }
      break;
    case(roll > modSkill):        //posible fumble
    
      if((roll % 11)===0 || ((roll % 10)===0 && this.props.melee.weaponQualities.dangerous) || ((roll % 10)===9 && this.props.melee.weaponQualities.dangerous)){
        //set FUMBLE
        // load fumble text
        this.setState({fumble: true})
        this.genFumble()
      }
      break;
      default:
  }
}

genCriticalHit(){
  // roll location random d100
  // roll severity random d100
  // setState text
  // text is in CriticalText.js
let findLocation = this.d100() 
//findLocation = 6; // debug
let critSeverityRoll = this.d100() 
//critSeverityRoll = 30 debugging
let index = 0; // Index are not evenly spaced
if      (critSeverityRoll <=  10) {index = 0;}
else if (critSeverityRoll <=  20) {index = 1;}
else if (critSeverityRoll <=  25) {index = 2;}
else if (critSeverityRoll <=  30) {index = 3;}
else if (critSeverityRoll <=  35) {index = 4;}
else if (critSeverityRoll <=  40) {index = 5;}
else if (critSeverityRoll <=  45) {index = 6;}
else if (critSeverityRoll <=  50) {index = 7;}
else if (critSeverityRoll <=  55) {index = 8;}
else if (critSeverityRoll <=  60) {index = 9;}
else if (critSeverityRoll <=  65) {index = 10;}
else if (critSeverityRoll <=  70) {index = 11;}
else if (critSeverityRoll <=  75) {index = 12;}
else if (critSeverityRoll <=  80) {index = 13;}
else if (critSeverityRoll <=  85) {index = 14;}
else if (critSeverityRoll <=  90) {index = 15;}
else if (critSeverityRoll <=  93) {index = 16;}
else if (critSeverityRoll <=  96) {index = 17;}
else if (critSeverityRoll <=  99) {index = 18;}
else if (critSeverityRoll === 100){index = 19;}

switch (true) {
    case (findLocation <= 9 ):
        this.setState({hitLocation: 'Head'})
        this.setState({criticalDescriptopn: critical.head[index].description})
        this.setState({criticalEffects: critical.head[index].effect})
        break;
        case (findLocation <= 24 ):
            this.setState({hitLocation: 'Left Arm'})
            this.setState({criticalDescriptopn: critical.arm[index].description})
            this.setState({criticalEffects: critical.arm[index].effect})
        break;
        case (findLocation <= 44 ):
            this.setState({hitLocation: 'Right Arm'})
            this.setState({criticalDescriptopn: critical.arm[index].description})
            this.setState({criticalEffects: critical.arm[index].effect})
        break;
        case (findLocation <= 79 ):
            this.setState({hitLocation: 'Body'})
            this.setState({criticalDescriptopn: critical.body[index].description})
            this.setState({criticalEffects: critical.body[index].effect})
        break;
        case (findLocation <= 89 ):
            this.setState({hitLocation: 'Left Leg'})
            this.setState({criticalDescriptopn: critical.leg[index].description})
            this.setState({criticalEffects: critical.leg[index].effect})
        break;
        case (findLocation <= 100 ):
            this.setState({hitLocation: 'Right Leg'})
            this.setState({criticalDescriptopn: critical.leg[index].description})
            this.setState({criticalEffects: critical.leg[index].effect})
        break;
        default:
}
}

genFumble(){
  let fumbRoll = this.d100();
  
  switch(true){
    case (fumbRoll <= 20):
      //set text 0
      this.setState({fumbleText: fumble.oops[0].results})
      break;
    case (fumbRoll <= 40):
        //set text 1
        this.setState({fumbleText: fumble.oops[1].results})
        break;
    case (fumbRoll <= 50):
      //set text 2
      this.setState({fumbleText: fumble.oops[2].results})
      break;
    case (fumbRoll <= 60):
      //set text 3
      this.setState({fumbleText: fumble.oops[3].results})
      break;
    case (fumbRoll <= 80):
      //set text 4
      this.setState({fumbleText: fumble.oops[4].results})
      break;
    case (fumbRoll <= 90):
      //set text 5
      this.setState({fumbleText: fumble.oops[5].results})
      break;
    default:
      //set text 6
      this.setState({fumbleText: fumble.oops[6].results})
      

  }
  
}

getSL(roll){
  let accurate = 0;
  if (this.props.melee.weaponQualities.accurate){
    accurate = 10;
  }
  const modSkill = Number(this.props.melee.SkillLevel) + Number(this.state.difficulty)+Number(this.props.advantage)*10 + Number(accurate);
  //console.log(modSkill)
  let skillSL;
  switch (true){
    case(roll <= modSkill):
        skillSL = Math.floor((modSkill - roll)/10);
        break;
    case(roll > modSkill):
        skillSL = Math.ceil((modSkill - roll)/10);
        break;
    default:
        // never
  }
  this.setState({sl: skillSL})
  this.props.updateRangedRoll({'roll':roll,'SL':skillSL})
}

    render(){
      let header;
      let fumbtxt;
      let slColor;
      let accurate = 0;
      
      if (this.props.melee.weaponQualities.accurate){
        accurate = 10;
      }
      //let qualitytxt;  **************************************** add later
      switch(true){
        case(this.state.autoSuccess):
        header = <div className="header-color-green">Automatic Success!</div>
        
        //
        break;
        case(this.state.autoFail):
        header = <div className="header-color-red">Automatic FAIL!</div>
        
        //
        break;
        case(this.state.critical):
        header = <div className="header-color-green">Critical!</div>
        
        //
        break;
        case(this.state.fumble):
        header = <div className="header-color-red">Fumble!</div>
        fumbtxt= <p><strong className="red">OOPS!:&nbsp;</strong>{this.state.fumbleText}</p>
        //
        break;
        default:
            header = <div className="">D100 -vs- Skill: {Number(this.props.melee.SkillLevel) + Number(this.state.difficulty) + Number(this.props.advantage)*10 + Number(accurate)}</div>
      }
      if ( this.state.d100 <= Number(this.props.melee.SkillLevel) + Number(this.state.difficulty) + Number(this.props.advantage)*10 + Number(accurate) ){
        slColor = 'green'
      }else {
        slColor = 'red'
      }
      //if()
     /*  switch(true){
        case (this.props.melee.weaponQualities.damaging):
          qualitytxt = 
      } */
      
        return (
          <div className="ui  ">
          <h2>Ranged Test</h2>
          <div className="ui divider"></div> 
          <div className="ui equal width stackable grid">
              <div className="ui row">
                  <div className="column five wide">
                    <Toggle 
                      name = {'accurate'}
                      label = {'Accurate'}
                      checkedVal = {this.props.melee.weaponQualities.accurate}
                      updateCheckedVal = { this.props.updateWeaponQualR }
                    />
                   
                    
                  </div>
                <div className="column five wide">
                <Toggle 
                      name = {'impale'}
                      label = {'Impale'}
                      checkedVal = {this.props.melee.weaponQualities.impale}
                      updateCheckedVal = { this.props.updateWeaponQualR }
                    />
                </div>
                <div className="column six wide">
                    <Toggle 
                      name = {'dangerous'}
                      label = {'Dangerous'}
                      checkedVal = {this.props.melee.weaponQualities.dangerous}
                      updateCheckedVal = { this.props.updateWeaponQualR }
                    />
                </div>
              </div>
              <div className="ui divider"></div> 
              <div className="ui row">
                  <div className="column eight wide">
                  
                  <SliderInput 
                        minV ={-40}
                        maxV ={60}
                        stepV ={10}
                        theDifficulity = { this.state.difficulty }
                        updateVal = { this.handleDifficulty }
                      />
                      <p>Combat Difficulty: ({this.state.difficulty})</p>
                  </div>
                <div className="column eight wide">
                
                <SkillInput 
                        thevalue = { this.props.melee.SkillLevel }
                        updateSkill = { this.props.updateRangedSkill }
                      />
                      <p>Ranged Weapon Skill</p>
                </div>
              </div>
              <div className="ui divider"></div> 
              <div className="ui row">
                  <div className="column eight wide">
                    <Advantage BtnColor={'yellow'}/>       
                  </div>
                  <div className="column eight wide">
                      <Button
                      fluid
                      size='huge'
                      color='yellow'
                      onClick={this.rollDice}
                      >Roll D100 - Ranged</Button>
                  </div>
              </div>  
              <div className="ui divider"></div> 
              <div className="ui row">
                  <div className="column eight wide">
                  <History history={this.props.melee.rolls}/>
                  </div>
                  <div className="column eight wide">
                      <Button
                      fluid
                      inverted
                      size='huge'
                      color='yellow'
                      onClick={()=>{this.props.resetRangedHistory()}}
                      >Clear History</Button>
                  </div>
              </div>  
              
        </div>
        <Modal
              open={this.state.modalOpen}
              onClose={this.handleClose}
            >
              <Modal.Header>{header}</Modal.Header>
              <Modal.Content>
                <p>{this.state.hitLocation}</p>
                <p>Rolled:&nbsp;{this.state.d100}</p>
                <h3>SL:<span className={slColor}>&nbsp;{this.state.sl}</span></h3>
                <p><strong className="red">{this.state.criticalDescriptopn}</strong>&nbsp;{this.state.criticalEffects}</p>
                {fumbtxt}
                
              </Modal.Content>
              <Modal.Actions>
                <Button color='yellow' onClick={this.handleClose} inverted>
                  <Icon name='gem outline' /> Close
                </Button>
              </Modal.Actions>
            </Modal>    
      </div>

      )}
};


const mapStateToProps = (state)=>{
  //console.log(state)
  return {  melee: state.rangedTest, advantage: state.advantage};
}

export default connect(mapStateToProps, { updateRangedSkill: updateRangedSkill, updateRangedRoll:updateRangedRoll, resetRangedHistory:resetRangedHistory, updateWeaponQualR:updateWeaponQualR})(RangedTest);