
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
//import { createStore } from 'redux';

import Warhammer from './Warhammer';
import reducers from './redux/reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers,  composeEnhancers(applyMiddleware())
);  
//const store = createStore(reducers);
ReactDOM.render(
    <Provider store={store}>
        <Warhammer />
    </Provider>, 
    document.querySelector('#root'));