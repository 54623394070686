import { UPDATE_CHANNELING_SKILL, UPDATE_CHANNELING_ROLLS, UPDATE_TALENT_CHANNELING, UPDATE_CHANNELING_SL, RESET_CHANNELING_HISTORY } from '../actionTypes'
const initialState = {
    SkillLevel: '1',
    talent: 0,
    sl: 0,
    rolls: []
}
const  channelingTestReducer = (state = initialState, action) =>{
    switch(action.type){
        case UPDATE_CHANNELING_SKILL:
            const { skill } = action.payload;
            return {...state, 
                SkillLevel: skill
            }
        case UPDATE_CHANNELING_ROLLS:
                const { roll } = action.payload;
            
                //console.log('updating history', roll);
                return { ...state,
                    rolls: [ ...state.rolls, roll]
                }
        case UPDATE_TALENT_CHANNELING:
                const { talent } = action.payload;
                console.log(talent);
                return { ...state,
                    talent: talent
                }
        case UPDATE_CHANNELING_SL:
                const { sl } = action.payload;
                //console.log('updating SL total ', sl)
                return { ...state,
                    sl: state.sl+sl.SL
                }
        case RESET_CHANNELING_HISTORY:
            return{...state,
                sl: 0,
                rolls:[]    
            }
        default:
            return state;
    }
 
}
export default channelingTestReducer