import { UPDATE_MELEE_SKILL, UPDATE_MELEE_ROLLS, RESET_MELEE_HISTORY, TOGGLE_WEAPON_QUAL_M } from '../actionTypes'
const initialState = {
    SkillLevel: '1',
    weaponQualities: {  'accurate':false,
                        'damaging':false,
                        'defensive':false,
                        'distract':false,
                        'entangle':false,
                        'fast':false,
                        'hack':false,
                        'impale':false,
                        'impact': false,
                        'penetrating':false,
                        'precise':false,
                        'pummel':false,
                        'trap':false,
                        'wrap':false },
    rolls: []
}
const  meleeTestReducer = (state = initialState, action) =>{
    switch(action.type){
        case UPDATE_MELEE_SKILL:
            const { skill } = action.payload;
            return {...state, 
                SkillLevel: skill
            }
        case UPDATE_MELEE_ROLLS:
                const { roll } = action.payload;
            
                //console.log('updating history', roll);
                return { ...state,
                    rolls: [ ...state.rolls, roll]
                }
        case RESET_MELEE_HISTORY:
            return{...state,
                rolls:[]    
            }
        case TOGGLE_WEAPON_QUAL_M:
            //console.log(action.payload)
            const { name, chk} = action.payload;
            return { ...state,
                weaponQualities:{...state.weaponQualities,
                    [name] : chk
                }

            }
        default:
            return state;
    }
        
    
 
}
export default meleeTestReducer
