import { combineReducers } from 'redux';
//import languageMagicSkillReducer from'./magic';
import skillTestReducer from './skillReducer';
import extendedSkillTestReducer from './extendedSkillReducer';
import meleeTestReducer from './meleeReducer';
import rangedTestReducer from './rangedReducer';
import castingTestReducer from './castingReducer';

import {   INCREASE_ADVANTAGE, DECREASE_ADVANTAGE } from '../actionTypes'
import channelingTestReducer from './channelingReducer';



const updateAdvantageReducer = (advantage = 0, action)=>{
    switch (action.type) {
        case INCREASE_ADVANTAGE:
            return advantage+1;
        case DECREASE_ADVANTAGE:
            return advantage-1;
        default:
            return advantage;
      }
}

/* const languageMagicSkillReducer = (languageMagicSkill = 1, action) =>{
    if(action.type === UPDATE_LANGUAGE_MAGIC_SKILL){
        return Number(action.payload);
    }
    return languageMagicSkill;
} */
/* 
const channelingMagicSkillReducer = (channelingMagicSkill = 1, action) =>{
    if(action.type === UPDATE_CHANNELING_MAGIC_SKILL){
        return Number(action.payload);
    }
    return channelingMagicSkill;
}

const updateToggleReducer = (toggleOne = false, action)=>{
    if (action.type === UPDATE_TOGGLE_ONE){
            return action.payload;
    }
    return toggleOne;
}

const updateAethyricDropdownSelectorReducer = (aethyric = 0, action)=>{
    if (action.type === UPDATE_DROPDOWN_AETHERIC){
            return action.payload;
    }
    return aethyric;
}
const updateInstinctiveDictionSelectorReducer = (instinctiveD = 0, action)=>{
    if (action.type === UPDATE_DROPDOWN_INSTINCTIVE_D){
            return action.payload;
    }
    return instinctiveD;
}
const updateBtn100Reducer = (random100 = 0, action)=>{
    switch (action.type) {
        case UPDATE_BTN_ROLL:
            return action.payload;
        default:
            return random100;
      }
}
 */

/* const updateSL = (SL = 0, action) =>{
    //console.log(action.payload)
    switch(action.type){
        case UPDATE_SL:
            if( (SL + action.payload) > 0){
                return SL + action.payload;
            }else return SL = 0;
        case RESET_SL:
            return SL = 0;
        default:
            return SL
    }
}
 */



export default combineReducers({
    /*  SL: updateSL, */
    /* random100val: updateBtn100Reducer,
    instinctiveDictionDropdownValue: updateInstinctiveDictionSelectorReducer,
    aethyricAttunementDropdownValue: updateAethyricDropdownSelectorReducer,
    toggleValue: updateToggleReducer,
    channelingSkill: channelingMagicSkillReducer,
    magicSkill: languageMagicSkillReducer, */


    advantage: updateAdvantageReducer,
    skillTest:skillTestReducer,
    extendedSkillTest: extendedSkillTestReducer,
    meleeTest: meleeTestReducer,
    rangedTest: rangedTestReducer,
    castingTest: castingTestReducer,
    channelingTest: channelingTestReducer
  });
  
  