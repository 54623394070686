import { UPDATE_EXTENDED_SKILL_TEST, UPDATE_EXTENDED_ROLLS, UPDATE_EXTENDED_SL, RESET_EXTENDED_SL_TOTAL } from '../actionTypes'
const initialState = {
    SkillLevel: '1',
    sl: 0,
    rolls: []
}
const  extendedSkillTestReducer = (state = initialState, action) =>{
    switch(action.type){
        case UPDATE_EXTENDED_SKILL_TEST:
                const { skill } = action.payload;
                return {...state, 
                    SkillLevel: skill
                }
        
        case UPDATE_EXTENDED_ROLLS:
            const { roll } = action.payload;
            
            //console.log('updating history', roll);
            return { ...state,
                rolls: [ ...state.rolls, roll]
            }
        
        case UPDATE_EXTENDED_SL:
            const { sl } = action.payload;
            //console.log('updating SL total ', sl)
            return { ...state,
                sl: state.sl+sl.SL
            }
        case RESET_EXTENDED_SL_TOTAL:
            return {...state,
                sl: 0,
                rolls: []
            }    
        default:
            return state;
    }
   
}
export default extendedSkillTestReducer
