import React from 'react';
import { connect } from 'react-redux';
import { updateChannelingSkill, updateChannelingRoll, resetChannelingHistory, updateChannelingSL, updateTalentChanneling } from '../../redux/actions/index'


import SkillInput from '../SkillInput';
import History from '../History';
import { Button } from 'semantic-ui-react';
import {Modal, Icon, Select} from 'semantic-ui-react';
import SliderInput from '../SliderInput';


import critical from '../../data/critical.json';
import miscast from '../../data/miscast.json';


//import './melee.css'



//import './css/skill.css';

class ChannelingTest extends React.Component{
  constructor(props) {
    super(props);
   
    this.state = { 
      modalOpen: false,
      d100: 0,
      sl: 0,
      autoSuccess: false,
      autoFail: false,
      critical: false,
      miscast: false,
      difficulty: 0,
      
      criticalDescriptopn: '',
      criticalEffects: '',
      fumbleText: '',
      miscastDiscription: '',
      miscastEffects: ''
    }
    this.rollDice = this.rollDice.bind(this);
    
  }

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })

  handleDifficulty = (e) => {this.setState({difficulty: e.target.value});}

  rollDice(){
    
    this.initResets();
    let roll = this.d100();
    //roll = 10
    
    this.setState({d100: roll});
    
    this.checkAutoFailSuccess(roll);
    this.getSL(roll);
    this.checkCritMiscast(roll);
    this.handleOpen();    
    
     
   
}

d100(){
  return Math.floor((Math.random()*100)+1);
}

initResets(){
  //reset state things for next roll
  this.setState({
    autoFail: false, 
    autoSuccess: false, 
    critical: false, 
    miscast: false, 
    
    criticalDescriptopn: '',
    criticalEffects: '',
    miscastDiscription: '',
    miscastEffects: ''})
  
}




reverse(n){
  n = n + "";
return Number(n.split("").reverse().join(""));
}

checkAutoFailSuccess(roll){
  switch(true){
    case (roll <= 5):
        this.setState({autoSuccess: true})
        break;
    case (roll >= 95):
        this.setState({autoFail: true})
        break;
    default:
      //neither
  }
}

checkCritMiscast(roll){
  
  const modSkill = Number(this.props.channeling.SkillLevel) + Number(this.state.difficulty);
  switch(true){
    case(roll <= modSkill):       //posible critical
    
      if((roll % 11)===0){
        //set critical
        // load critical text
    
        this.setState({critical: true});
        this.props.updateChannelingSL({'SL': 9000})
        //this.genCriticalHit();
        // if no feet MISCAST
        if( this.props.channeling.talent === 0 ){
          this.setState({miscast: true});
          this.genMiscast('minor');
          //console.log('minor mc')
          

        }
      }
      break;
    case(roll > modSkill):        //posible fumble
    //console.log('posible fumb 11 =',(roll % 11)===0)
      if((roll % 11) === 0 || (roll % 10) === 0){
        
        this.setState({miscast: true});

        this.genMiscast('major');
          

      }
      break;
      default:
  }
}


genMiscast(severity){
  const roll = Math.floor((Math.random()*20));
  switch(severity){
  case ('minor'):
    this.setState ({miscastDiscription: miscast.minor[roll].description, miscastEffects: miscast.minor[roll].effect })
    break;
  case ('major'):
    this.setState ({miscastDiscription: miscast.major[roll].description, miscastEffects: miscast.major[roll].effect })
    break;
  default:
  }
}

getSL(roll){
  
  const modSkill = Number(this.props.channeling.SkillLevel) + Number(this.state.difficulty);
  //console.log(modSkill)
  let skillSL;
  switch (true){
    case(roll <= modSkill):
        skillSL = Math.floor((modSkill - roll)/10);
        if(this.props.channeling.talent > 0){
          skillSL += Number(this.props.channeling.talent);
        }
        break;
    case(roll > modSkill):
        skillSL = Math.ceil((modSkill - roll)/10);
        break;
    default:
        // never
  }
  this.setState({sl: skillSL})
  this.props.updateChannelingRoll({'roll':roll,'SL':skillSL})

  if(skillSL+Number(this.props.channeling.sl)>0){
    this.props.updateChannelingSL({'SL': skillSL})
  }else {
    const makeZero = Number(this.props.channeling.sl)*-1;
    this.props.updateChannelingSL({'SL': makeZero})
  }


}

    render(){
      let header;
      let miscastText;
      let slColor;
      //let qualitytxt;  **************************************** add later
      switch(true){
        /* case(this.state.autoSuccess):
        header = <div className="header-color-green">Automatic Success!</div>
        
        //
        break;
        case(this.state.autoFail):
        header = <div className="header-color-red">Automatic FAIL!</div>
        
        //
        break; */
        case(this.state.critical):
        header = <div className="header-color-green">Critical - FULL SL</div>
        if (this.state.miscast){
          miscastText= <p><strong className="red">{this.state.miscastDiscription}</strong> &nbsp;{this.state.miscastEffects}</p>
        }
        //
        break;
        case(this.state.miscast):
        header = <div className="header-color-red">Miscast!</div>
        miscastText= <p><strong className="red">{this.state.miscastDiscription}</strong> &nbsp;{this.state.miscastEffects}</p>
        //
        break;
        default:
            header = <div className="">D100 -vs- Skill: {Number(this.props.channeling.SkillLevel) + Number(this.state.difficulty)  }</div>
      }
      if ( this.state.d100 <= Number(this.props.channeling.SkillLevel) + Number(this.state.difficulty)   ){
        slColor = 'green'
      }else {
        slColor = 'red'
      }
      //if()
     /*  switch(true){
        case (this.props.channeling.weaponQualities.damaging):
          qualitytxt = 
      } */
      const InstinctiveDictionOptions = [
        { key: '0', value: 0, text: '0' },
        { key: '1', value: 1, text: 'x1' },
        { key: '2', value: 2, text: 'x2' },
        { key: '3', value: 3, text: 'x3' },
        { key: '4', value: 4, text: 'x4' },
        { key: '5', value: 5, text: 'x5' },
        { key: '6', value: 6, text: 'x6' },
        { key: '7', value: 7, text: 'x7' },
        { key: '8', value: 8, text: 'x8' },
        { key: '9', value: 9, text: 'x9' },
      ]
        return (
          <div className="ui  ">
          <h2>Channeling Test</h2>
          <div className="ui divider"></div> 
          <div className="ui equal width stackable grid">
              <div className="ui row">
                <div className="column sixteen wide">
                  
                 
                </div>
              </div>
              <div className="ui divider"></div> 
              <div className="ui row">
                  <div className="column eight wide">
                  
                  <SliderInput 
                        minV ={-40}
                        maxV ={60}
                        stepV ={10}
                        theDifficulity = { this.state.difficulty }
                        updateVal = { this.handleDifficulty }
                      />
                      <p>Channeling Difficulty: ({this.state.difficulty})</p>
                  </div>
                <div className="column eight wide">
                <Select 
                    fluid
                    placeholder='0' 
                    //onChange={(e, {value})=>this.props.updateInstinctiveDictionDropdown({value})} 
                    onChange={(e, {value})=>this.props.updateTalentChanneling({value})} 
                    options={InstinctiveDictionOptions} 
                    selection value={this.props.channeling.talent} 
                   />
                   <p>Talent: Aethyric Attunement</p>
                
                </div>
              </div>
              <div className="ui divider"></div> 
              <div className="ui row">
                  <div className="column eight wide">
                  <SkillInput 
                        thevalue = { this.props.channeling.SkillLevel }
                        updateSkill = { this.props.updateChannelingSkill }
                      />
                      <p>Channeling Skill</p>
                  </div>
                  <div className="column eight wide">
                      <Button
                      fluid
                      size='huge'
                      color='purple'
                      onClick={this.rollDice}
                      >Roll D100 => Total SL:{this.props.channeling.sl}</Button>
                  </div>
              </div>  
              <div className="ui divider"></div> 
              <div className="ui row">
                  <div className="column eight wide">
                  <History history={this.props.channeling.rolls}/>
                  </div>
                  <div className="column eight wide">
                      <Button
                      fluid
                      inverted
                      size='huge'
                      color='purple'
                      onClick={()=>{this.props.resetChannelingHistory()}}
                      >Reset => Total SL: {this.props.channeling.sl}</Button>
                  </div>
              </div>  
              
        </div>
        <Modal
              open={this.state.modalOpen}
              onClose={this.handleClose}
            >
              <Modal.Header>{header}</Modal.Header>
              <Modal.Content>
                
                <p>Rolled:&nbsp;{this.state.d100}</p>
                <h3>SL:<span className={slColor}>&nbsp;{this.state.sl}</span></h3>
                
                {miscastText}
                
              </Modal.Content>
              <Modal.Actions>
                <Button color='purple' onClick={this.handleClose} inverted>
                  <Icon name='gem outline' /> Close
                </Button>
              </Modal.Actions>
            </Modal>    
      </div>

      )}
};


const mapStateToProps = (state)=>{
  //console.log(state)
  return {  channeling: state.channelingTest };
}

export default connect(mapStateToProps, { updateChannelingSkill: updateChannelingSkill, updateChannelingRoll: updateChannelingRoll, updateChannelingSL:updateChannelingSL, resetChannelingHistory: resetChannelingHistory, updateTalentChanneling:updateTalentChanneling})(ChannelingTest);