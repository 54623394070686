import React from 'react';
import { connect } from 'react-redux';
import { updateCastingSkill, updateCastingRoll, resetCastingHistory,updateWeaponQualM, updateTalentDrop } from '../../redux/actions/index'

import Advantage from '../Advantage';
import SkillInput from '../SkillInput';
import History from '../History';
import { Button } from 'semantic-ui-react';
import {Modal, Icon, Select} from 'semantic-ui-react';
import SliderInput from '../SliderInput';


import critical from '../../data/critical.json'
import miscast from '../../data/miscast.json';


//import './melee.css'



//import './css/skill.css';

class CastingTest extends React.Component{
  constructor(props) {
    super(props);
   
    this.state = { 
      modalOpen: false,
      d100: 0,
      sl: 0,
      autoSuccess: false,
      autoFail: false,
      critical: false,
      miscast: false,
      fumble: false,
      difficulty: 0,
      hitLocation: '',
      criticalDescriptopn: '',
      criticalEffects: '',
      fumbleText: '',
      miscastDiscription: '',
      miscastEffects: ''
    }
    this.rollDice = this.rollDice.bind(this);
    
  }

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })

  handleDifficulty = (e) => {this.setState({difficulty: e.target.value});}

  rollDice(){
    
    this.initResets();
    let roll = this.d100();
    //roll = 10
    
    this.setState({d100: roll});
    this.hitLocation(roll);
    this.checkAutoFailSuccess(roll);
    this.getSL(roll);
    this.checkCritMiscast(roll);
    this.handleOpen();    
    
     
   
}

d100(){
  return Math.floor((Math.random()*100)+1);
}

initResets(){

  //reset state things for next roll
  this.setState({
    autoFail: false, 
    autoSuccess: false, 
    critical: false, 
    miscast: false, 
    hitLocation: '',
    criticalDescriptopn: '',
    criticalEffects: '',
    miscastDiscription: '',
    miscastEffects: ''})
  
}

hitLocation(roll){
  let findLocation = 0;
  if (roll < 10 ){
      findLocation = roll*10;
  }else{findLocation = this.reverse(roll);}
  
  
  switch (true) {
      case (findLocation <= 9 ):
          this.setState({hitLocation: 'Head'})
          break;
          case (findLocation <= 24 ):
          this.setState({hitLocation:'Left Arm'})
          break;
          case (findLocation <= 44 ):
          this.setState({hitLocation:'Right Arm'})
          break;
          case (findLocation <= 79 ):
          this.setState({hitLocation:'Body'})
          break;
          case (findLocation <= 89 ):
          this.setState({hitLocation:'Left Leg'})
          break;
          case (findLocation <= 100 ):
          this.setState({hitLocation:'Right Leg'})
          break;
          default:
          
  }
  
}



reverse(n){
  n = n + "";
return Number(n.split("").reverse().join(""));
}

checkAutoFailSuccess(roll){
  switch(true){
    case (roll <= 5):
        this.setState({autoSuccess: true})
        break;
    case (roll >= 95):
        this.setState({autoFail: true})
        break;
    default:
      //neither
  }
}

checkCritMiscast(roll){
  
  const modSkill = Number(this.props.casting.SkillLevel) + Number(this.state.difficulty)+Number(this.props.advantage)*10;
  switch(true){
    case(roll <= modSkill):       //posible critical
    
      if((roll % 11)===0){
        //set critical
        // load critical text
    
        this.setState({critical: true});
        this.genCriticalHit();
        // if no feet MISCAST
        if( this.props.casting.talent === 0 ){
          this.setState({miscast: true});
          this.genMiscast('minor');
         // console.log('minor mc')
          

        }
      }
      break;
    case(roll > modSkill):        //posible fumble
    
      if((roll % 11)===0){
         
        this.setState({miscast: true});

        this.genMiscast('minor');
          
      }
      break;
      default:
  }
}

genCriticalHit(){
  // roll location random d100
  // roll severity random d100
  // setState text
  // text is in CriticalText.js
let findLocation = this.d100() 
//findLocation = 6; // debug
let critSeverityRoll = this.d100() 
//critSeverityRoll = 30 debugging
let index = 0; // Index are not evenly spaced
if      (critSeverityRoll <=  10) {index = 0;}
else if (critSeverityRoll <=  20) {index = 1;}
else if (critSeverityRoll <=  25) {index = 2;}
else if (critSeverityRoll <=  30) {index = 3;}
else if (critSeverityRoll <=  35) {index = 4;}
else if (critSeverityRoll <=  40) {index = 5;}
else if (critSeverityRoll <=  45) {index = 6;}
else if (critSeverityRoll <=  50) {index = 7;}
else if (critSeverityRoll <=  55) {index = 8;}
else if (critSeverityRoll <=  60) {index = 9;}
else if (critSeverityRoll <=  65) {index = 10;}
else if (critSeverityRoll <=  70) {index = 11;}
else if (critSeverityRoll <=  75) {index = 12;}
else if (critSeverityRoll <=  80) {index = 13;}
else if (critSeverityRoll <=  85) {index = 14;}
else if (critSeverityRoll <=  90) {index = 15;}
else if (critSeverityRoll <=  93) {index = 16;}
else if (critSeverityRoll <=  96) {index = 17;}
else if (critSeverityRoll <=  99) {index = 18;}
else if (critSeverityRoll === 100){index = 19;}

switch (true) {
    case (findLocation <= 9 ):
        this.setState({hitLocation: 'Head'})
        this.setState({criticalDescriptopn: critical.head[index].description})
        this.setState({criticalEffects: critical.head[index].effect})
        break;
        case (findLocation <= 24 ):
            this.setState({hitLocation: 'Left Arm'})
            this.setState({criticalDescriptopn: critical.arm[index].description})
            this.setState({criticalEffects: critical.arm[index].effect})
        break;
        case (findLocation <= 44 ):
            this.setState({hitLocation: 'Right Arm'})
            this.setState({criticalDescriptopn: critical.arm[index].description})
            this.setState({criticalEffects: critical.arm[index].effect})
        break;
        case (findLocation <= 79 ):
            this.setState({hitLocation: 'Body'})
            this.setState({criticalDescriptopn: critical.body[index].description})
            this.setState({criticalEffects: critical.body[index].effect})
        break;
        case (findLocation <= 89 ):
            this.setState({hitLocation: 'Left Leg'})
            this.setState({criticalDescriptopn: critical.leg[index].description})
            this.setState({criticalEffects: critical.leg[index].effect})
        break;
        case (findLocation <= 100 ):
            this.setState({hitLocation: 'Right Leg'})
            this.setState({criticalDescriptopn: critical.leg[index].description})
            this.setState({criticalEffects: critical.leg[index].effect})
        break;
        default:
}
}
genMiscast(severity){
  const roll = Math.floor((Math.random()*20));
  switch(severity){
  case ('minor'):
    this.setState ({miscastDiscription: miscast.minor[roll].description, miscastEffects: miscast.minor[roll].effect })
    break;
  case ('major'):
    this.setState ({miscastDiscription: miscast.major[roll].description, miscastEffects: miscast.major[roll].effect })
    break;
  default:
  }
}

getSL(roll){
  
  const modSkill = Number(this.props.casting.SkillLevel) + Number(this.state.difficulty)+Number(this.props.advantage)*10;
  //console.log(modSkill)
  let skillSL;
  switch (true){
    case(roll <= modSkill):
        skillSL = Math.floor((modSkill - roll)/10);
        if(this.props.casting.talent > 0){
          skillSL += Number(this.props.casting.talent);
        }
        break;
    case(roll > modSkill):
        skillSL = Math.ceil((modSkill - roll)/10);
        break;
    default:
        // never
  }
  this.setState({sl: skillSL})
  this.props.updateCastingRoll({'roll':roll,'SL':skillSL})
}

    render(){
      let header;
      let miscastText;
      let slColor;
      //let qualitytxt;  **************************************** add later
      switch(true){
        case(this.state.autoSuccess):
        header = <div className="header-color-green">Automatic Success!</div>
        
        //
        break;
        case(this.state.autoFail):
        header = <div className="header-color-red">Automatic FAIL!</div>
        
        //
        break;
        case(this.state.critical):
        header = <div className="header-color-green">Critical!</div>
        if (this.state.miscast){
          miscastText= <p><strong className="red">OOPS!:&nbsp;</strong>{this.state.miscastDiscription}: &nbsp;{this.state.miscastEffects}</p>
        }
        
        //
        break;
        case(this.state.miscast):
        header = <div className="header-color-red">Fumble!</div>
        miscastText= <p><strong className="red">OOPS!:&nbsp;</strong>{this.state.miscastDiscription}: &nbsp;{this.state.miscastEffects}</p>
        //
        break;
        default:
            header = <div className="">D100 -vs- Skill: {Number(this.props.casting.SkillLevel) + Number(this.state.difficulty) + Number(this.props.advantage)*10 }</div>
      }
      if ( this.state.d100 <= Number(this.props.casting.SkillLevel) + Number(this.state.difficulty) + Number(this.props.advantage)*10  ){
        slColor = 'green'
      }else {
        slColor = 'red'
      }
      //if()
     /*  switch(true){
        case (this.props.casting.weaponQualities.damaging):
          qualitytxt = 
      } */
      const InstinctiveDictionOptions = [
        { key: '0', value: 0, text: '0' },
        { key: '1', value: 1, text: 'x1' },
        { key: '2', value: 2, text: 'x2' },
        { key: '3', value: 3, text: 'x3' },
        { key: '4', value: 4, text: 'x4' },
        { key: '5', value: 5, text: 'x5' },
        { key: '6', value: 6, text: 'x6' },
        { key: '7', value: 7, text: 'x7' },
        { key: '8', value: 8, text: 'x8' },
        { key: '9', value: 9, text: 'x9' },
      ]
        return (
          <div className="ui  ">
          <h2>Casting Test</h2>
          <div className="ui divider"></div> 
          <div className="ui equal width stackable grid">
              <div className="ui row">
                <div className="column sixteen wide">
                  <p>Talent: Instinctive Diction</p>
                  <Select 
                    fluid
                    placeholder='0' 
                    //onChange={(e, {value})=>this.props.updateInstinctiveDictionDropdown({value})} 
                    onChange={(e, {value})=>this.props.updateTalentDrop({value})} 
                    options={InstinctiveDictionOptions} 
                    selection value={this.props.casting.talent} 
                   />
                </div>
              </div>
              <div className="ui divider"></div> 
              <div className="ui row">
                  <div className="column eight wide">
                  
                  <SliderInput 
                        minV ={-40}
                        maxV ={60}
                        stepV ={10}
                        theDifficulity = { this.state.difficulty }
                        updateVal = { this.handleDifficulty }
                      />
                      <p>Combat Difficulty: ({this.state.difficulty})</p>
                  </div>
                <div className="column eight wide">
                
                <SkillInput 
                        thevalue = { this.props.casting.SkillLevel }
                        updateSkill = { this.props.updateCastingSkill }
                      />
                      <p>Casting Skill</p>
                </div>
              </div>
              <div className="ui divider"></div> 
              <div className="ui row">
                  <div className="column eight wide">
                    <Advantage BtnColor={'blue'}/>       
                  </div>
                  <div className="column eight wide">
                      <Button
                      fluid
                      size='huge'
                      color='blue'
                      onClick={this.rollDice}
                      >Roll D100 - Casting</Button>
                  </div>
              </div>  
              <div className="ui divider"></div> 
              <div className="ui row">
                  <div className="column eight wide">
                  <History history={this.props.casting.rolls}/>
                  </div>
                  <div className="column eight wide">
                      <Button
                      fluid
                      inverted
                      size='huge'
                      color='blue'
                      onClick={()=>{this.props.resetCastingHistory()}}
                      >Clear History</Button>
                  </div>
              </div>  
              
        </div>
        <Modal
              open={this.state.modalOpen}
              onClose={this.handleClose}
            >
              <Modal.Header>{header}</Modal.Header>
              <Modal.Content>
                <p>{this.state.hitLocation}</p>
                <p>Rolled:&nbsp;{this.state.d100}</p>
                <h3>SL:<span className={slColor}>&nbsp;{this.state.sl}</span></h3>
                <p><strong className="red">{this.state.criticalDescriptopn}</strong>&nbsp;{this.state.criticalEffects}</p>
                {miscastText}
                
              </Modal.Content>
              <Modal.Actions>
                <Button color='blue' onClick={this.handleClose} inverted>
                  <Icon name='gem outline' /> Close
                </Button>
              </Modal.Actions>
            </Modal>    
      </div>

      )}
};


const mapStateToProps = (state)=>{
  //console.log(state)
  return {  casting: state.castingTest, advantage: state.advantage};
}

export default connect(mapStateToProps, { updateCastingSkill: updateCastingSkill, updateCastingRoll: updateCastingRoll, resetCastingHistory: resetCastingHistory, updateTalentDrop:updateTalentDrop, updateWeaponQualM:updateWeaponQualM})(CastingTest);