import React from 'react';

const Welcome = () => {
  const greeting = 'Warhammer D100';
  return <Headline value={greeting} />;
};
const Headline = ({ value }) => {
  return (
    <div>
    <h1>{value}</h1><p>ToDo: </p>
    </div>
    );
};
export default Welcome;