import React from 'react';
import { Button } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { increaseAdvantage, decreaseAdvantage } from '../redux/actions/index'


class Advantage extends React.Component{
    constructor(props) {
        super(props);
               
        this.limits = this.limits.bind(this);
      }

    limits(data){ 
        
        switch (data.incDec) {
            case "up":
                 if(data.val>=0 && data.val<10){
                    this.props.increaseAdvantage()
                } 
                break;    
            case "down":
                 if(data.val>=1 && data.val<=10){
                    this.props.decreaseAdvantage()
                } 
                break;
            default:
                return true;
          }


            
            
        
    }
    
   render(){
        
        return (
        <div>
            <div className="ui   grid">
            <div className="ui row">
                    <div className="column four wide">
                    <Button size='huge' fluid color={this.props.BtnColor} onClick={()=>this.limits({val: this.props.advantage, incDec: "down"})} >-</Button>
                    </div>
                    <div className="column eight wide">
                        <div align="center">
                        <h2>Advantage:&nbsp;{this.props.advantage}</h2>
                        </div>
                    </div>
                    <div className="column four wide">
                        
                        <Button size='huge' fluid color={this.props.BtnColor} onClick={()=>this.limits({val: this.props.advantage, incDec: "up"})} >+</Button>
                    </div>
            </div>
            </div>
            
        
        
        </div>
        );
    }
}

const mapStateToProps = (state)=>{
    return {  advantage: state.advantage};
}
export default connect(mapStateToProps, { increaseAdvantage: increaseAdvantage, decreaseAdvantage: decreaseAdvantage})(Advantage);