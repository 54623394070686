import React from 'react';

class SkillInput extends React.Component{
    constructor(props) {
        super(props);
        this.updateSkill = this.updateSkill.bind(this);
      }
      
      updateSkill(e){
          this.props.updateSkill(e);
      }
    render(){
        
        return  (
            <div className="ui input fluid">
                   <input
                   type="text"
                   name="SkillNumber"
                   onChange={(e)=>this.props.updateSkill(e)}
                   value = {this.props.thevalue}
                   id="Skill"
                       />
            </div>   );
        
    }
}

export default(SkillInput);