
import {  Link } from "react-router-dom";
import React, { Component } from 'react'
import { Menu } from 'semantic-ui-react'

export default class MenuBar extends Component {
  state = { activeItem: null }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render() {
    const { activeItem } = this.state

    return (
      <Menu inverted pointing fluid widths={8} stackable >
          <Menu.Item 
          header 
          name='Home'
          icon='fort awesome'
          active={activeItem === '2'}
          onClick={this.handleItemClick}
          as={ Link } 
          to='/'
          ></Menu.Item>
        <Menu.Item
          name='Skill Test'
          color = {'green'}
          active={activeItem === 'Skill Test'}
          onClick={this.handleItemClick}
          as={ Link } 
          to='skillTest'
        />
        <Menu.Item
          name='Extended Test'
          color = {'orange'} 
          active={activeItem === 'Extended Test'}
          onClick={this.handleItemClick}
          as={ Link } 
          to='extendedTest'
        />
        <Menu.Item
          name='Melee'
          color = {'red'}
          active={activeItem === 'Melee'}
          onClick={this.handleItemClick}
          as={ Link } 
          to='meleeTest'
        />
        <Menu.Item
          name='Ranged'
          color = {'yellow'}
          active={activeItem === 'Ranged'}
          onClick={this.handleItemClick}
          as={ Link } 
          to='rangedTest'
        />
        <Menu.Item
          name='Casting'
          color = {'blue'}
          active={activeItem === 'Casting'}
          onClick={this.handleItemClick}
          as={ Link } 
          to='castingTest'
        />
         <Menu.Item
          name='Channeling'
          color = {'violet'}
          active={activeItem === 'Channeling'}
          onClick={this.handleItemClick}
          as={ Link } 
          to='channelingTest'
        />
        <Menu.Item
          name='Pray to God'
          color = {'pink'}
          active={activeItem === 'Pray to God'}
          onClick={this.handleItemClick}
          as={ Link } 
          to='prayTest'
        />
      </Menu>
    )
  }
}
