import React from 'react';

const History = (props) => {
    
      let historyItem = props.history.map((d, index)=>{
        return(<li key ={generateKey(index)}>Roll:{d.roll} => SL:{d.SL}</li>);
    })  
    
    return (
        <div>
            <h3>Roll History</h3>
        <ul>
            {historyItem}
        </ul>
        </div>
        );
};
const generateKey = (pre) => {
    return `${ pre }_${ new Date().getTime() }`;
}
export default History;