export const UPDATE_LANGUAGE_MAGIC_SKILL = 'UPDATE_LANGUAGE_MAGIC_SKILL'
export const UPDATE_CHANNELING_MAGIC_SKILL = 'UPDATE_CHANNELING_MAGIC_SKILL'
export const UPDATE_TOGGLE_ONE = 'UPDATE_TOGGLE_ONE'
export const UPDATE_DROPDOWN_AETHERIC = 'UPDATE_DROPDOWN_AETHERIC'
export const UPDATE_DROPDOWN_INSTINCTIVE_D = 'UPDATE_DROPDOWN_INSTINCTIVE_D'
export const UPDATE_BTN_ROLL = 'UPDATE_BTN_ROLL'
export const INCREASE_ADVANTAGE = 'INCREASE_ADVANTAGE'
export const DECREASE_ADVANTAGE = 'DECREASE_ADVANTAGE'
export const UPDATE_SL = 'UPDATE_SL'
export const RESET_SL = 'RESET_SL'

export const UPDATE_SKILL_TEST = 'UPDATE_SKILL_TEST'
export const UPDATE_SKILL_ROLLS = 'UPDATE_SKILL_ROLLS'
export const RESET_SKILL_HISTORY = 'RESET_SKILL_HISTORY'

export const UPDATE_EXTENDED_SKILL_TEST = 'UPDATE_EXTENDED_SKILL_TEST'
export const UPDATE_EXTENDED_ROLLS = 'UPDATE_EXTENDED_ROLLS'
export const UPDATE_EXTENDED_SL = 'UPDATE_EXTENDED_SL'
export const RESET_EXTENDED_SL_TOTAL = 'RESET_EXTENDED_SL_TOTAL'

export const UPDATE_MELEE_SKILL = 'UPDATE_MELEE_SKILL'
export const UPDATE_MELEE_ROLLS = 'UPDATE_MELEE_ROLLS'
export const RESET_MELEE_HISTORY = 'RESET_MELEE_HISTORY'
export const TOGGLE_WEAPON_QUAL_M = 'TOGGLE_WEAPON_QUAL_M'

export const UPDATE_RANGED_SKILL = 'UPDATE_RANGED_SKILL'
export const UPDATE_RANGED_ROLLS = 'UPDATE_RANGED_ROLLS'
export const RESET_RANGED_HISTORY = 'RESET_RANGED_HISTORY'
export const TOGGLE_WEAPON_QUAL_R = 'TOGGLE_WEAPON_QUAL_R'

export const UPDATE_CASTING_SKILL = 'UPDATE_CASTING_SKILL'
export const UPDATE_CASTING_ROLLS = 'UPDATE_CASTING_ROLLS'
export const UPDATE_TALENT_CASTING = 'UPDATE_TALENT_CASTING'
export const RESET_CASTING_HISTORY = 'RESET_CASTING_HISTORY'

export const UPDATE_CHANNELING_SKILL = 'UPDATE_CHANNELING_SKILL'
export const UPDATE_CHANNELING_ROLLS = 'UPDATE_CHANNELING_ROLLS'
export const UPDATE_TALENT_CHANNELING = 'UPDATE_TALENT_CHANNELING'
export const UPDATE_CHANNELING_SL = 'UPDATE_CHANNELING_SL'
export const RESET_CHANNELING_HISTORY = 'RESET_CHANNELING_HISTORY'