import React from 'react';
import Welcome from './components/welcome';
import CastingPage from './components/castingTest';
import ChannelingPage from './components/channelingTest';
import ExtendedTestPage from './components/extendedTest';
import PrayTest from './components/prayTest';
import SkillTest from './components/skillTest';
import MeleeTest from './components/meleeTest';
import RangedTest from './components/rangedTest';
import Menu from './components/header';
import { BrowserRouter, HashRouter, Route } from "react-router-dom";

//import LanguageMagicInput from './components/castingTest/LanguageMagicInput';



function Warhammer() {
  return (
    <div className="ui container">
      <HashRouter>
      <Menu />
        <Route path = "/" exact component = {Welcome} />
        <Route path = "/skillTest/" component = {SkillTest} />
        <Route path = "/extendedTest/" component = {ExtendedTestPage} />
        <Route path = "/meleeTest/" component = {MeleeTest} />
        <Route path = "/rangedTest" component = {RangedTest} />
        <Route path = "/castingTest/" component = {CastingPage} />
        <Route path = "/channelingTest/" component = {ChannelingPage} />
        <Route path = "/prayTest/" component = {PrayTest} />
      </HashRouter>
    </div>
  );
}

export default Warhammer;
