import { UPDATE_RANGED_SKILL, UPDATE_RANGED_ROLLS, RESET_RANGED_HISTORY, TOGGLE_WEAPON_QUAL_R } from '../actionTypes'
const initialState = {
    SkillLevel: '1',
    weaponQualities: {  'accurate':false,
                        'blackpowder': false,
                        'blast': false,
                        'damaging':false,
                        'impale':false,
                        'penetrating':false,
                        'precise':false,
                        'pummel':false,
                        'repeater': false,
                        'dangerous': false,
                        'imprecise': false
                        },
    rolls: []
}
const  rangedTestReducer = (state = initialState, action) =>{
    switch(action.type){
        case UPDATE_RANGED_SKILL:
            const { skill } = action.payload;
            return {...state, 
                SkillLevel: skill
            }
        case UPDATE_RANGED_ROLLS:
                const { roll } = action.payload;
            
                //console.log('updating history', roll);
                return { ...state,
                    rolls: [ ...state.rolls, roll]
                }
        case RESET_RANGED_HISTORY:
            return{...state,
                rolls:[]    
            }
        case TOGGLE_WEAPON_QUAL_R:
            //console.log(action.payload)
            const { name, chk} = action.payload;
            return { ...state,
                weaponQualities:{...state.weaponQualities,
                    [name] : chk
                }

            }
        default:
            return state;
    }
        
    
 
}
export default rangedTestReducer
