import {UPDATE_SKILL_TEST, UPDATE_SKILL_ROLLS, RESET_SKILL_HISTORY, 
        UPDATE_EXTENDED_ROLLS, UPDATE_EXTENDED_SKILL_TEST, UPDATE_EXTENDED_SL, RESET_EXTENDED_SL_TOTAL, 
        UPDATE_MELEE_SKILL, UPDATE_MELEE_ROLLS,  RESET_MELEE_HISTORY, TOGGLE_WEAPON_QUAL_M,
        UPDATE_RANGED_SKILL, UPDATE_RANGED_ROLLS,  RESET_RANGED_HISTORY, TOGGLE_WEAPON_QUAL_R,
        UPDATE_CASTING_SKILL, UPDATE_CASTING_ROLLS, UPDATE_TALENT_CASTING, RESET_CASTING_HISTORY,  
        UPDATE_CHANNELING_SKILL, UPDATE_CHANNELING_ROLLS, UPDATE_TALENT_CHANNELING, UPDATE_CHANNELING_SL, RESET_CHANNELING_HISTORY,  
        INCREASE_ADVANTAGE, DECREASE_ADVANTAGE } from '../actionTypes'

export const increaseAdvantage = () =>{
    return{
        type: INCREASE_ADVANTAGE
    }
}
export const decreaseAdvantage = () =>{
        return{
            type: DECREASE_ADVANTAGE
        }
}

// skill test actions
export const updateSkillTest = (e)=>{
    return {
        type: UPDATE_SKILL_TEST,
        payload: {skill: e.target.value}
    }
}
export const updateSkillRoll = (d) =>{
    return{
        type: UPDATE_SKILL_ROLLS,
        payload: {roll: d}
    }
}
export const resetSkillHistory = () =>{
    return {
        type: RESET_SKILL_HISTORY
    }
}

// extended skill test page
export const updateExtendedSkillTest = (e)=>{
    return {
        type: UPDATE_EXTENDED_SKILL_TEST,
        payload: {skill: e.target.value}
    }
}
export const updateExtendedSL = (sl)=>{
    return {
        type: UPDATE_EXTENDED_SL,
        payload: {sl: sl}
    }
}
export const updateExtendedRoll = (d)=>{
    return {
        type: UPDATE_EXTENDED_ROLLS,
        payload: {roll: d}
    }
}
export const resetExtendedSL = () =>{
    return {
        type: RESET_EXTENDED_SL_TOTAL
    }
}

// melww test
export const updateMeleeSkill = (e)=>{
    return {
        type: UPDATE_MELEE_SKILL,
        payload: {skill: e.target.value}
    }
}
export const updateMeleeRoll = (d)=>{
    return {
        type: UPDATE_MELEE_ROLLS,
        payload: {roll: d}
    }
}
export const resetMeleeHistory = () =>{
    return {
        type: RESET_MELEE_HISTORY
    }
}
export const updateWeaponQualM = (e) =>{
    return {
        type: TOGGLE_WEAPON_QUAL_M,
        payload: {name: e.target.name, chk: e.target.checked}
    }
}

// ranged test
export const updateRangedSkill = (e)=>{
    return {
        type: UPDATE_RANGED_SKILL,
        payload: {skill: e.target.value}
    }
}
export const updateRangedRoll = (d)=>{
    return {
        type: UPDATE_RANGED_ROLLS,
        payload: {roll: d}
    }
}
export const resetRangedHistory = () =>{
    return {
        type: RESET_RANGED_HISTORY
    }
}
export const updateWeaponQualR = (e) =>{
    return {
        type: TOGGLE_WEAPON_QUAL_R,
        payload: {name: e.target.name, chk: e.target.checked}
    }
}

// casting test actions
export const updateCastingSkill = (e)=>{
    return {
        type: UPDATE_CASTING_SKILL,
        payload: {skill: e.target.value}
    }
}
export const updateTalentDrop = (e) => {
        return{
            type: UPDATE_TALENT_CASTING,
            payload: {talent: e.value}
        }
}
export const updateCastingRoll = (d) =>{
    return{
        type: UPDATE_CASTING_ROLLS,
        payload: {roll: d}
    }
}
export const resetCastingHistory = () =>{
    return {
        type: RESET_CASTING_HISTORY
    }
}


// channeling test actions
export const updateChannelingSkill = (e)=>{
    return {
        type: UPDATE_CHANNELING_SKILL,
        payload: {skill: e.target.value}
    }
}
export const updateTalentChanneling = (e) => {
        return{
            type: UPDATE_TALENT_CHANNELING,
            payload: {talent: e.value}
        }
}
export const updateChannelingRoll = (d) =>{
    return{
        type: UPDATE_CHANNELING_ROLLS,
        payload: {roll: d}
    }
}
export const updateChannelingSL = (sl)=>{
    return {
        type: UPDATE_CHANNELING_SL,
        payload: {sl: sl}
    }
}
export const resetChannelingHistory = () =>{
    return {
        type: RESET_CHANNELING_HISTORY
    }
}






/* 
export const updateLanguageMagicSkill =(e) =>{
    return {
        type: UPDATE_LANGUAGE_MAGIC_SKILL,
        payload: { skill: e.target.value, something: 'Yeet', jsObj: {name: 'sam', last: 'hedley'}}
    }
}
export const updateChannelingMagicSkill =(e) =>{
    return {
        type: UPDATE_CHANNELING_MAGIC_SKILL,
        payload: e.target.value
    }
}

export const updateToggle = (e) =>{
    return {
        type: UPDATE_TOGGLE_ONE,
        payload: e.target.checked

    }
}
export const updateAethyricAttunementDropdown = (e) =>{
    //console.log(e);
    return {
        type: UPDATE_DROPDOWN_AETHERIC,
        payload: e.value

    }
}
export const updateInstinctiveDictionDropdown = (e) =>{
    return {
        type: UPDATE_DROPDOWN_INSTINCTIVE_D,
        payload: e.value

    }
}
export const updateBtn100Roll = (data) =>{
    return {
        type: UPDATE_BTN_ROLL,
        payload: data.diceRoll

    }
}

export const updateSL = (sl) =>{
   // console.log(sl)
    return{
        type: UPDATE_SL,
        payload: sl
    }
}
export const resetSL = () =>{
    // console.log(sl)
     return{
         type: RESET_SL
     }
 } */