import { UPDATE_SKILL_TEST, UPDATE_SKILL_ROLLS, RESET_SKILL_HISTORY } from '../actionTypes'
const initialState = {
    SkillLevel: '1',
    rolls: []
}
const  skillTestReducer = (state = initialState, action) =>{
    switch(action.type){
        case UPDATE_SKILL_TEST:
            const { skill } = action.payload;
            return {...state, 
                SkillLevel: skill
            }
        case UPDATE_SKILL_ROLLS:
                const { roll } = action.payload;
            
                //console.log('updating history', roll);
                return { ...state,
                    rolls: [ ...state.rolls, roll]
                }
        case RESET_SKILL_HISTORY:
            return{...state,
                rolls:[]    
            }
        default:
            return state;
    }
 
}
export default skillTestReducer
