import React from 'react';
import { connect } from 'react-redux';
import { updateExtendedSkillTest, updateExtendedRoll, updateExtendedSL, resetExtendedSL } from '../../redux/actions/index'

//import SliderInput from '../SliderInput';
import SkillInput from '../SkillInput';
import { Button } from 'semantic-ui-react';
import {Modal, Icon} from 'semantic-ui-react';
import SliderInput from '../SliderInput';
import History from '../History';
import dificulityTable from '../../data/difficultyTable.json';
import './css/skill.css';

class ExtendedTestPage extends React.Component{
  constructor(props) {
    super(props);
   
    this.state = { 
      modalOpen: false,
      d100: 0,
      sl: 0,
      autoSuccess: false,
      autoFail: false,
      difficulty: 3
    }
    this.rollDice = this.rollDice.bind(this);
    
  }

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })

  handleDifficulty = (e) => {this.setState({difficulty: e.target.value});}

  rollDice(){
    
    this.initResets()
    let roll = this.d100()
    //roll = 99
    
    this.setState({d100: roll})
    this.checkAutoFailSuccess(roll)
    this.getSL(roll)
    this.handleOpen();        
    
}

d100(){
  return Math.floor((Math.random()*100)+1);
}

initResets(){
  //reset state things for next roll
  this.setState({autoFail: false})
  this.setState({autoSuccess: false})
}

checkAutoFailSuccess(roll){
  switch(true){
    case (roll <= 5):
        this.setState({autoSuccess: true})
        break;
    case (roll >= 95):
        this.setState({autoFail: true})
        break;
    default:
      //neither
  }
}
getSL(roll){
  //const dificulityMod = dificulityTable.dificulityTable[this.state.difficulty].modifier;
  const modSkill = Number(this.props.skillVal.SkillLevel) + Number(dificulityTable.dificulityTable[this.state.difficulty].modifier);
  
  let skillSL;
  switch (true){
    case(roll <= modSkill):
        skillSL = Math.floor((modSkill - roll)/10);
        break;
    case(roll > modSkill):
        skillSL = Math.ceil((modSkill - roll)/10);
        break;
    default:
        // never
  }
  this.setState({sl: skillSL})
  this.props.updateExtendedRoll({'roll':roll,'SL':skillSL})
  if(skillSL+Number(this.props.skillVal.sl)>0){
    this.props.updateExtendedSL({'SL': skillSL})
  }else {
    const makeZero = Number(this.props.skillVal.sl)*-1;
    this.props.updateExtendedSL({'SL': makeZero})
  }
}

    render(){
      let header;
      let slColor;
      switch(true){
        case(this.state.autoSuccess):
        header = <div className="header-color-green">Automatic Success!</div>
        
        //
        break;
        case(this.state.autoFail):
        header = <div className="header-color-red">Automatic FAIL!</div>
        
        //
        break;
        default:
            header = <div className="">D100 -vs- Skill: {Number(this.props.skillVal.SkillLevel) + Number(dificulityTable.dificulityTable[this.state.difficulty].modifier)}</div>
      }
      if ( this.state.d100 <= Number(this.props.skillVal.SkillLevel) + Number(dificulityTable.dificulityTable[this.state.difficulty].modifier) ){
        slColor = 'green'
      }else {
        slColor = 'red'
      }
      //header =(Number(this.props.skillVal.SkillLevel) + Number(dificulityTable.dificulityTable[this.state.difficulty].modifier))
        return (
          <div className="ui  ">
          <h2>Extended Skill Test</h2>
          <div className="ui divider"></div> 
          <div className="ui equal width stackable grid">
              <div className="ui row">
                  <div className="column eight wide">
                      <SliderInput 
                        minV ={0}
                        maxV ={6}
                        stepV ={1}
                        theDifficulity = { this.state.difficulty }
                        updateVal = { this.handleDifficulty }
                      />
                  </div>
                <div className="column eight wide">
                <h3>{dificulityTable.dificulityTable[this.state.difficulty].description} ( {dificulityTable.dificulityTable[this.state.difficulty].modifier} )</h3>
                </div>
              </div>
              <div className="ui row">
                  <div className="column eight wide">
                      <SkillInput 
                        thevalue = { this.props.skillVal.SkillLevel }
                        updateSkill = { this.props.updateExtendedSkillTest }
                      />
                  </div>
                  <div className="column eight wide">
                      <Button
                      fluid
                      size='huge'
                      color='orange'
                      onClick={this.rollDice}
                      >Roll D100 => Total SL:{this.props.skillVal.sl}</Button>
                  </div>
              </div>  
              <div className="ui row">
                  <div className="column eight wide">
                    <History history={this.props.skillVal.rolls}/>                      
                  </div>
                  <div className="column eight wide">
                      <Button
                      fluid
                      inverted
                      size='huge'
                      color='orange'
                      onClick={()=>{this.props.resetExtendedSL()}}
                      >Reset => Total SL: {this.props.skillVal.sl}</Button>
                  </div>
              </div>  
              
        </div>
        <Modal
              open={this.state.modalOpen}
              onClose={this.handleClose}
            >
              <Modal.Header>{header}</Modal.Header>
              <Modal.Content>
                <h3>Rolled:{this.state.d100}</h3>
                <h3>SL:<span className={slColor}>&nbsp;{this.state.sl}</span></h3>
                
              </Modal.Content>
              <Modal.Actions>
                <Button color='orange' onClick={this.handleClose} inverted>
                  <Icon name='gem outline' /> Close
                </Button>
              </Modal.Actions>
            </Modal>    
      </div>

      )}
};


const mapStateToProps = (state)=>{
  //console.log(state)
  return {  skillVal: state.extendedSkillTest};
}

export default connect(mapStateToProps, { updateExtendedSkillTest: updateExtendedSkillTest, updateExtendedRoll: updateExtendedRoll, updateExtendedSL:updateExtendedSL, resetExtendedSL:resetExtendedSL})(ExtendedTestPage);