import React from 'react';



class ToggleSwitch extends React.Component{
    constructor(props) {
        super(props);
        this.updateCheckedVal = this.updateCheckedVal.bind(this);
      }
      
       updateCheckedVal(e){
          this.props.updateCheckedVal(e);
       }

   render(){
         
        return (
            <div className="ui toggle checkbox">
                <input type="checkbox" name={this.props.name}
                checked={this.props.checkedVal}
                onChange={(e)=>this.props.updateCheckedVal(e)}
                />
                <label >{this.props.label}</label>
            </div>
        );
    }
}


export default ToggleSwitch;